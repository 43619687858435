<template>
    <section class="modulo-section">
        <div class="row mx-0">
            <div class="menu-left border">
                <div class="row mx-0">
                    <div class="col-12 text-center">
                        <p class="text-86 f-15 f-500 py-4 border-bottom">Información</p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <router-link :to="{name:'informacion.preguntas.frecuentes'}" class="menu-sub-item mb-3 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Preguntas frecuentes</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                        <router-link :to="{name:'informacion.terminos.condiciones'}" class="menu-sub-item mb-3 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Términos y condiciones</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                        <router-link :to="{name:'informacion.policitas.privacidad'}" class="menu-sub-item mb-3 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Políticas de privacidad</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col">
                <router-view class="mt-4" />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
          
        }
    },
}
</script>

<style lang="scss" scoped>
.modulo-section{
    .menu-left{
        width: 240px;
        height: calc(100vh - 55px);
        background: #FAFBFC 0% 0% no-repeat padding-box;
        box-shadow: 1px 0px 2px #00000029;
        .menu-sub-item{
            &:hover{
                @extend .bg-active;
                cursor: pointer;
            }
        }
        .bg-active{
            background: #EBECF0;
            i.icon-right-open{
                opacity: 1;
            }
        }
        i.icon-right-open{
            opacity: 0.5;
        }
    }
    a{
        color: inherit;
        &:hover{
            background: #EBECF0;
        }
        &.router-link-exact-active.router-link-active{
            background: #EBECF0;
            
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
